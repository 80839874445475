// only eng translation updating with new translation, other translations not updated yet
const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'

const translationEn = {
  home: 'Home',
  cases: 'Cases',
  profile: 'Profile',
  yourNotifications: 'Your Notifications',
  yourCases: 'Your Cases',
  status: 'Status',
  id: 'ID',
  startDate: 'Start Date',
  caseName: 'Case Name',
  welcome: 'Welcome',
  [lorem]: lorem,
  '404pageNotFound': '404 - Page Not Found',
  '404errorPageMessage':
    "We're sorry, but the page you're looking for might have been removed, had its name changed, or is temporarily unavailable.",
  cancel: 'Cancel',
  save: 'Save',
  email: 'Email',
  username: 'Username',
  specialization: 'Specialization',
  institution: 'Institution',
  languageSettings: 'Language settings',
  selectLanguage: 'Select language',
  allCases: 'All Cases',
  filters: 'Filters',
  date: 'Date',
  selectFilters: 'Select Filters',
  selectStartDate: 'Select Start Date',
  selectEndDate: 'Select End Date',
  clearFilters: 'Clear Filters',
  apply: 'Apply',
  signInToYourAccount: 'Sign in to your account',
  password: 'Password',
  logIn: 'Log in',
  signUp: 'Sign up',
  yourEmail: 'Your Email',
  rememberMe: 'Remember me',
  noAccountYet: "Don't have an account yet?",
  edit: 'Edit',
  name: 'Name',
  caseId: 'Case ID',
  results: 'Results',
  positive: 'Positive',
  negative: 'Negative',
  sex: 'Sex',
  age: 'Age',
  male: 'Male',
  female: 'Female',
  earlyDiagnostics: 'Early Diagnostics',
  suggestions: 'Suggestions',
  riskScore: 'Risk Score',
  riskFactors: 'Risk Factors',
  bmi: 'BMI',
  activityLevel: 'Activity Level',
  colonoscopy: 'Colonoscopy',
  ctScan: 'CT Scan',
  labTests: 'Lab Tests',
  reports: 'Reports',
  imaging: 'Imaging',
  findings: 'Findings',
  preparationDetails: 'Preparation Details',
  procedureDetails: 'Procedure Details',
  procedureImages: 'Procedure Images',
  image: 'Image',
  bloodTests: 'Blood Tests',
  molecularTests: 'Molecular Tests',
  viewResults: 'View Results',
  histopathologyFindings: 'Histopathology Findings',
  personalInfo: 'Personal Info',
  viewAllUpdates: 'View All Updates',
  welcomeBack: 'Welcome back to your workspace.',
  search: 'Search',
  addCase: 'Add Case',
  pleaseCreateAnAccount: 'Please create an account.',
  next: 'Next',
  alreadyHaveAccount: 'Already have an account?',
  signIn: 'Sign in',
  yourAccount: 'Your Account',
  aboutYou: 'About You',
  verification: 'Verification',
  fullName: 'Full Name',
  specialty: 'Specialty',
  medicalInstitution: 'Medical Institution',
  back: 'Back',
  verificationText:
    'Check your email and enter the verification Code we have sent you to your registered email account.',
  code: 'Code',
  resendCode: 'Resend Code',
  verifyAccount: 'Verify Account',
  firstName: 'First Name',
  lastName: 'Last Name',
  userType: 'User Type',
  doctor: 'Doctor',
  nurse: 'Nurse',
  maintainer: 'Maintainer',
  author: 'Author',
  typeFileName: 'Type - File Name',
  uploadFiles: 'Upload Files',
  manager: 'Manager',
  caseNickname: 'Case Nickname',
  labTechnician: 'Lab Technician',
  admin: 'Admin',
  chooseOneOption: 'Choose an option',
  getStarted: 'Get Started',
  'An AI-assisted DSS oriented to facilitate the work of medical experts and clinicians during their day-to-day activities when screening and treating citizens and patients for CRC.':
    'An AI-assisted DSS oriented to facilitate the work of medical experts and clinicians during their day-to-day activities when screening and treating citizens and patients for CRC.',
  'Clinical Decision Support System (cDSS)':
    'Clinical Decision Support System (cDSS)',
  welcomeTo: 'Welcome to',
  profileType: 'Profile type',
  emailRequired: 'Email is required',
  passwordRequired: 'Password is required',
  invalidEmailFormat: 'Invalid email format',
  userTypeRequired: 'User type is required',
  doctorSpecialtyRequired: 'Doctor specialty is required',
  firstNameRequired: 'First name is required',
  lastNameRequired: 'Last name is required',
  medicalInstitutionRequired: 'Medical institution is required',
  loginToYourAccount: 'Login to your account',
  allUsers: 'All Users',
  create: 'Create',
  addNewUser: 'Add new User',
  createNewUser: 'Create new User',
  areYouSureYouWantLogout: 'Are you sure you want to Logout?',
  logout: 'Logout',
  deleteAccount: 'Delete Account',
  areYouSureYouWantDeleteUser: 'Are you sure you want to delete the User?',
  yesDeleteUser: 'Yes, Delete User',
  UserMaintainer: 'User Maintainer',
  Nurse: 'Nurse',
  Doctor: 'Doctor',
  LabTechnician: 'Lab Technician',
  DoctorManager: 'Doctor Manager',
  noEntriesFound: 'No Entries Found',
  patients: 'Patients',
  registerNewPatient: 'Register New Patient',
  createAndEditNewCases: 'Create and edit new cases',
  registerNewPatients: 'Register new patients',
  userList: 'User List',
  viewAndEditExistingUsers: 'View and edit existing users',
  addNew: 'Add New',
  allPatients: 'All Patients',
  addNewPatient: 'Add New Patient',
  yesDeletePatient: 'Yes, Delete patient',
  areYouSureYouWantDeletePatient: 'Are you sure you want to delete Patient?',
  deletePatient: 'Delete Patient',
  addNewCase: 'Add New Case',
  dateCreated: 'Date created',
  createNewPatient: 'Create new Patient',
  createNewCase: 'Create new Case',
  deleteCase: 'Delete Case',
  areYouSureYouWantDeleteCase: 'Are you sure you want to delete Case?',
  yesDeleteCase: 'Yes, Delete case',
  telephone: 'Telephone',
  patient: 'Patient',
  case: 'Case',
  dateOfBirth: 'Date of Birth',
  gender: 'Gender',
  phone: 'Phone',
  phoneIsRequired: 'Phone is required',
  caseStatusRequired: 'Case status is required',
  caseNameRequired: 'Case name is required',
  patientRequired: 'Patient is required',
  caseStatus: 'Case Status',
  caseDate: 'Case Date',
  caseDateRequired: 'Case date is required',
  otherGender: 'Other',
  returnToLoginPage: 'Return to Login Page',
  activateYourAccount: 'Activate your Account',
  goToHomePage: 'Go to Home Page',
  goToLoginPage: 'Go to Login Page',
  reloadPage: 'Reload Page',
  comments: 'Comments',
  'Your account is created. Log in with your email and password to get started.':
    'Your account is created. Log in with your email and password to get started',
  active: 'Active',
  inactive: 'Inactive',
  resendActivation: 'Resend activation',
  activation: 'Activation',
  resendAccountActivationEmail: 'Resend an account activation email to selected User',
  sendMail: 'Send mail',
  accountIsAlreadyActive: 'This account is already active.',
  yourAccountActivated: 'Your account has been successfully activated',
  youWillReceiveEmailWithInstructions: 'You will shortly receive a follow-up email with instructions to reset your password and start using your account.',
  delete: 'Delete',
  resetPassword: 'Reset Password',
  sessionHasExpired: 'Your session has expired',
  forgotPassword: 'Forgot Password?',
  forgotPasswordDescription: 'No worries! Enter your email below to receive password reset instructions.',
  sendEmail: 'Send Email',
  backToSingIn: 'Back to Sign In',
  confirmPasswordRequired: 'Confirm password is required',
  passwordsMustMatch: 'Passwords must match',
  confirmPassword: 'Confirm Password',
  resetYourPassword: 'Reset Your Password',
  resetPasswordDescription: 'Your new password must be different than the one used before.',
  uploadFile: 'Upload File',
  update: 'Update',
  oncoCtc: 'ONCO CTC',
  oncoNmr: 'ONCO NMR',
  addNewFile: 'Add New File',
  rangeOfModel95: '95% Range of Model',
  showGraph: 'Show Graph',
  parameter: 'Parameter',
  result: 'Result',
  lipoproteins: 'Lipoproteins',
  metabolites: 'Metabolites',
  glycoproteins: 'Glycoproteins',
  withinRange: 'Within range',
  outOfRange: 'Out of range',
  extraInfo: 'Extra Info',
  lod: 'LOD',
  maxConcentration95: '95% Max Concentration',
  SelectResultToShowGraph: 'Select an out of range Result from the list to show a Graph here.',
  nmrParameterTables: 'NMR Parameter Tables',
  showExcelFile: 'Show Excel File',
  firstResults: 'First Results',
  finalResult: 'Final Results',
  finals: 'Finals',
  finalResultDisclaimer: 'This information is for general guidance only and does not replace professional medical advice. Only a qualified health care proffesional can provide a diagnosis.',
  confidence: 'Confidence',
  riskLevel: 'Risk Level',
  oncoRisteDisclaimer: 'These risk-based stratification level and results and meta-data information are provided for informational purposes only and are not a substitute for advice provided by a clinician or other qualified health care professional. The results may indicate whether you are at risk for CRC, but only a clinician can provide a diagnosis.',
  clusteringApproach: 'Clustering Approach',
  riskFactorsToConsider: 'Risk factors to consider',
  smokingLevel: 'Smoking Level',
  smokingStatus: 'Smoking Status',
  geneticFactors: 'Genetic Factors',
  alcoholConsumption: 'Alchohol Consumption',
  rules: 'Rules',
  weightOfRule: 'Weight of Rule',
  actualOrChange: 'Actual / Change',
  semiEmpiricalApproach: 'Semi-Empirical Approach',
  areYouSureYouWantDeleteRule: 'Are you sure you want to delete Rule?',
  yesDeleteRule: 'Yes, Delete \rule!',
  deleteRule: 'Delete Rule',
  createNewRule: 'Create New Rule',
  import: 'Import',
  allRules: 'All Rules',
  ruleName: 'Rule name',
  copyAttributesFromAnotherRule: 'Copy attributes from another rule',
  oncoVOC: 'Onco-VOC',
  oncoNMR: 'Onco-NMR',
  oncoCRISPR: 'Onco-CRISPR',
  oncoCTC: 'Onco-CTC',
  editRule: 'Edit Rule',
  casesUsed: 'Cases Used',
  updateCategory: 'Update Category',
  category: 'Category',
  uploader: 'Uploader',
  fileName: 'File Name',
  fitTest: 'Fit Test',
  other: 'Other',
  biopsy: 'Biopsy',
  selectValue: 'Select Value',
  submit: 'Submit',
  editReport: 'Edit Report',
  deleteReport: 'Delete Report',
  areYouSureYouWantDeleteReport: 'Are you sure you want to delete report?',
  yesDeleteReport: 'Yes, Delete Report.',
  type2Diabetes: 'Type 2 Diabetes',
  crohnsDisease: 'Crohn\'s Disease',
  ibd: 'IBD',
  dietFactors: 'Diet Factors',
  carcinogenExposure: 'Carcinogen Exposure',
  createdBy: 'Created By',
  clinicalTests: 'Clinical Tests',
}

export default translationEn
