import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useTable,
  useGlobalFilter,
  usePagination,
} from 'react-table'
import { BsFilter } from 'react-icons/bs'
import Pagination from './Table/Pagination'
import Search from './Table/Search'
import MainButton from './UI/MainButton'
import WhiteBox from './UI/WhiteBox'
import cn from '../utils/cn'

export default function ReportsTable({
  title,
  setShowModal,
  columns,
  data,
  rowsPerPage = 5,
  className,
  showAddButton = false,

}) {
  const [t] = useTranslation()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    setPageSize,
    gotoPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: rowsPerPage,
      },
    },
    useGlobalFilter,
    usePagination,
  )

  const print = 'print:m-0 print:p-0 print:overflow-x-visible print:text-[10px]'

  return (
    <div
      className={cn('border-r border-r-gray-solitude pl-5 pr-16', className)}
    >
      <div className="flex items-baseline justify-between">
        <h2 className="text-xl font-medium">{title}</h2>
        <div className="overflow-auto print:overflow-visible">
          <div className="pb-10 w-full flex pl-5 justify-between gap-3">
            <Search setFilter={setGlobalFilter} />
            <div className="ml-2">
              <MainButton
                text={t('filters')}
                onClick={() => setShowModal(true)}
                isOutline
                textColor="primary"
              >
                <BsFilter className="mr-2 text-xl text-primary" />
              </MainButton>
            </div>
            {showAddButton
              && <MainButton className="bg-[#E5E7EB] text-[#707070] border-[#707070] hover:opacity-90 hover:bg-[#E5E7EB] hover:text-[#707070] hover:border-[#707070]">
                + Add Result
              </MainButton>
            }
          </div>
        </div>
      </div>
      <WhiteBox otherStyle="border border-gray-solitude p-5">
        <table
          {...getTableProps()}
          className={`w-full border-collapse py-2
          print:min-w-[400px] print:max-w-[1100px] text-[#959292]`}
        >
          <thead>
            {headerGroups.map((headerGroup, idx) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    style={{ width: 'auto' }}
                    {...column.getHeaderProps()}
                    className={`
                      px-4 pt-2
                     ${print} text-left text-gray-light text-lg whitespace-nowrap border-b border-b-gray-light pb-4`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="h-[100px]">
            {page.map((row, rowIndex) => {
              prepareRow(row)
              return (
                <tr
                  key={rowIndex}
                  className={`transition-colors duration-200 hover:bg-[rgba(0,0,0,0.05)] cursor-pointer
                  border-b border-b-gray-line last:border-b-0`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, idx) => (
                    <td
                      key={idx}
                      {...cell.getCellProps()}
                      className={`px-4 py-2
                        ${print} h-[50px] text-black`}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination
          pageLength={page.length}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          backgroundColor="pale"
          rounded={false}
        />
      </WhiteBox>
    </div>
  )
}
