import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaListUl } from 'react-icons/fa6'
import moment from 'moment'
import Avatar from '../UI/Avatar'
import WhiteBox from '../UI/WhiteBox'
import SwitchButton from '../UI/SwitchButton'

export default function SingleRuleCard({
  profile, size = 'w-full', shadow = false,
}) {
  const [t] = useTranslation()
  const flexer = 'flex items-baseline gap-2 text-xl'
  const separator = 'justify-end border-l border-gray-light rounded-l-2 pl-2'

  return (
    <WhiteBox width={size}>
      <div className="flex items-center gap-4 p-6 h-[150px]">
        <Avatar width="w-16">
          <div className="ml-[14px] mt-[14px]">
            <FaListUl size={32} />
          </div>
        </Avatar>
        <div>
          <p className="mb-2 text-2xl leading-7 uppercase">{profile.ruleName}</p>
          <div className={flexer}>
            <div className={flexer}>
              <p>{t('id')}:</p>
              <p className="text-gray-light">{profile.id}</p>
            </div>
            <div className={`${flexer} ${separator}`}>
              <p>{t('dateCreated')}:</p>
              <p className="text-gray-light">{moment(profile.dateCreated).format('DD/MM/YYYY')}</p>
            </div>
            <div className={`flex items-center gap-2 text-xl ${separator}`}>
              <p>{t('status')}:</p>
              <div className="text-gray-light"><SwitchButton value={profile.status} /></div>
            </div>
          </div>
        </div>
      </div>
    </WhiteBox>
  )
}
