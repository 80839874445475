import React, { useState } from 'react'
import ReportsTable from '../ReportsTable'
import Modal from '../Modals/Modal'

const BiopsyList = () => {
  const [showModal, setShowModal] = useState(false)

  return <>
        {showModal && (
            <Modal onClose={() => setShowModal(false)}>
                <div>
                    <p className="text-defaultText text-sm text-center">
                        There are no filters yet...
                    </p>
                </div>
            </Modal>
        )}
        <ReportsTable showAddButton title="Biopsy" data={[]} columns={[]} setShowModal={setShowModal} className="border-r-0" />
    </>
}

export default BiopsyList
