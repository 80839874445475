import React from 'react'
import FinalResult from './FinalResult'
import decisionTree from '../../assets/images/decision_tree.png'

export default function Finals() {
  return (
    <div className="px-8 pt-10 py-6 md:grid md:grid-cols-8 md:gap-8 xl:border-r xl:border-gray-border-2">
      <div className="col-span-4 pr-8 border-r border-r-gray-light">
        <FinalResult />
      </div>
        <div className="w-full col-span-4">
            <img className="object-cover max-w-full" src={decisionTree} alt=""/>
        </div>
    </div>

  )
}
