import ColonoscopyList from './ColonoscopyList'
import ImagingList from './ImagingList'
import BiopsyList from './BiopsyList'

const ClinicalTests = () => (
    <div className="px-5 py-12 grid grid-cols-2 gap-10">
        <div className="grid">
            <ColonoscopyList/>
            <ImagingList/>
        </div>
        <div className="grid">
           <BiopsyList />
        </div>
    </div>
)

export default ClinicalTests
