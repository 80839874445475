import {
  Form,
  NavLink,
  useSubmit,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import LoginSignupWrapper from '../../components/Forms/LoginSignupWrapper'
import LoginInput from '../../components/Forms/Input'
import LoginSignupSubmit from '../../components/Forms/LoginSignupSubmit'
import validationSchema from '../../utils/validation-schema'
import EuImage from '../../components/UI/EuImage'

export default function LoginPage() {
  const [t] = useTranslation()
  const submit = useSubmit()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema.login,
    onSubmit: async (values) => {
      submit(values, { method: 'post' })
    },
  })

  return (
      <>

    <LoginSignupWrapper isLogin>
      <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-defaultText">
        {t('loginToYourAccount')}
      </h1>
      <Form
        className="space-y-4 md:space-y-6"
        onSubmit={formik.handleSubmit}
        method="post"
      >
        <LoginInput
          name="email"
          type="email"
          label={t('yourEmail')}
          placeholder="name@example.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={!!(formik.touched.email && formik.errors.email)}
          errorMessage={formik.errors.email}
        />
        <LoginInput
          name = "password"
          type="password"
          label={t('password')}
          placeholder="••••••••"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={!!(formik.touched.password && formik.errors.password)}
          errorMessage={formik.errors.password}
        />
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <div className="flex items-center h-4">
              <input
                id="remember"
                aria-describedby="remember"
                type="checkbox"
                className="w-4 h-4 bg-white border rounded checkbox border-gray-light"
                required=""
              />
            </div>
            <div className="ml-1 text-xs">
              <label htmlFor="remember" className="text-gray-light">
                {t('rememberMe')}
              </label>
            </div>
          </div>
          <NavLink
            to="/forgot-password"
            className="text-xs text-primary-content hover:underline"
          >
            {t('forgotPassword')}
          </NavLink>
        </div>
        <LoginSignupSubmit />
      </Form>

    </LoginSignupWrapper>
      <EuImage className="mx-auto"/>
      </>
  )
}
