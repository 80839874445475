import { useTranslation } from 'react-i18next'
import {
  Form,
  useActionData,
  useNavigate,
} from 'react-router-dom'
import { useEffect } from 'react'
import MainButton from '../UI/MainButton'
import cn from '../../utils/cn'

export default function ModalActionContent({
  title, description, buttonLabel, buttonType = 'button', id, buttonAction, buttonClassName, icon, logoutHandler, closeModalHandler, fieldName, testOnClick,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const actionData = useActionData()
  useEffect(() => {
    if (actionData?.response?.status === 'ok') {
      closeModalHandler()
      navigate(actionData.response.path)
    }
  }, [actionData, navigate])

  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-5">
      {icon}
      <p className="text-2xl uppercase">{t(title)}</p>
      <p className="font-medium text-[20px]">{t(description)}</p>
      <div className="flex flex-col items-center w-full gap-3">
        {buttonType === 'submit' && (
          <Form method={buttonAction} replace className="flex justify-center w-full">
            <input name={fieldName} defaultValue={id} hidden />
            <MainButton
              text={t(buttonLabel)}
              type={buttonType}
              className={cn('w-full md:max-w-[400px] rounded-3xl', buttonClassName)} />
          </Form>
        )}
        {buttonType !== 'submit' && (
          <MainButton
            text={t(buttonLabel)}
            type={buttonType}
            className={cn('w-full md:max-w-[400px] rounded-3xl', buttonClassName)}
            onClick={() => {
              if (testOnClick) {
                testOnClick()
                closeModalHandler()
                return
              }
              logoutHandler()
            }}
          />
        )}

        <MainButton
          text={t('cancel')}
          type="button"
          onClick={closeModalHandler}
          className="rounded-3xl w-full md:max-w-[400px]"
          isCancel
        />
      </div>
    </div>
  )
}
