import React from 'react'
import MainButton from '../UI/MainButton'

export default function Modal({
  title, onClose, children, type = 'default', customStyle = '',
}) {
  const handleClose = () => {
    onClose()
  }

  const backdropClasses = type === 'default' ? '' : ''

  return (
    <>
      <div
        className={`fixed z-40 inset-0 bg-[rgba(0,0,0,0.40)] transition-opacity duration-300 ${backdropClasses} ${customStyle}`}
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
        tabIndex="0"
      />
      <div className="fixed z-50 flex justify-center transform -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2">
        <div className="modal-box min-h-[500px] min-w-[400px] sm:min-w-[500px] md:min-w-[700px]">
          {type === 'default' && (
            <button
              className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
              onClick={handleClose}
            >
              ✕
            </button>
          )}
          <h3 className="text-lg font-bold">{title}</h3>
          <div className="box-content mb-22">{children}</div>
          {type === 'close' && (
            <div className="modal-action">
              <MainButton className="btn" onClick = {handleClose}>Close</MainButton>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
