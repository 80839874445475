import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styles from '../../css/components/UI/MainNavigation.module.css'
import oncoscreenLogo from '../../assets/images/oncoclide.png'
import Drawer from './Drawer'
import BurgerMenu from './BurgerMenu'
import userUtils from '../../utils/users-utils'
import NavOptionsDropdown from '../Dropdown/NavOptionsDropdown'
import navUtils from '../../utils/nav-utils'

export default function MainNavigation({ onOpenModal }) {
  const authUser = userUtils.getUser()
  const { role } = authUser
  const menuItems = navUtils.getMenuItemsForRole(role)
  const mobileMenuItems = navUtils.getMobileMenuItemsForRole(role)

  const [t] = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const renderMenu = () => menuItems.map((obj) => (
    <li key={obj.title} className="group-item">
      <NavLink
        to={obj.route}
        className="p-0 bg-transparent active:text-secondary"
        end
      >
        {({ isActive }) => (
          <div className={`${isActive ? 'text-secondary px-[6px]' : ''}`}>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={obj.icon} size="sm" />
              {t(obj.title)}
            </div>
            <div
              className={`absolute top-10 w-full h-[8px] ${isActive ? `${styles.underline}` : `${styles.underlineSlide}`
                // eslint-disable-next-line indent
                }`}
            />
          </div>
        )}
      </NavLink>
    </li>
  ))

  return (
    <div className="relative w-full z-[100]">
      <header>
        <nav className="px-2 xl:px-12 navbar bg-neutral text-neutral-content">
          <div className="flex-1">
            <div className="h-auto">
              <img
                src={oncoscreenLogo}
                className="object-contain w-full h-full max-w-[150px]"
                alt="Oncoscreen logo"
              />
            </div>
            <p className="hidden px-2 text-xl font-semibold text-center normal-case md:px-4 md:block">
              cDSS
            </p>
          </div>
          <div className="flex-none">
            <ul className="hidden gap-16 px-1 mr-16 text-xl menu xl:menu-horizontal">
              {renderMenu()}
            </ul>
            <NavOptionsDropdown onOpenModal={onOpenModal} />
            <BurgerMenu
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              onClick={(prevState) => setIsOpen(!prevState)}
            />
          </div>
        </nav>
      </header>
      {isOpen && <Drawer menuItems={mobileMenuItems} setIsOpen={setIsOpen} />}
    </div>
  )
}
