import React, { useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ReportsTable from '../ReportsTable'
import Modal from '../Modals/Modal'
import TableButtonGroup from '../Table/TableButtonGroup'

const imagingData = [
  {
    file_name: 'colon_2024_001.jpg',
    uploader: 'Dr. Smith',
    date: '2024-09-15',
  },
  {
    file_name: 'colon_2024_002.jpg',
    uploader: 'Dr. Johnson',
    date: '2024-09-16',
  },
  {
    file_name: 'colon_2024_003.jpg',
    uploader: 'Dr. Lee',
    date: '2024-09-17',
  },
]

const ImagingList = () => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const columns = [
    {
      Header: t('typeFileName'),
      accessor: 'file_name',
    },
    {
      Header: t('uploader'),
      accessor: 'uploader',
    },
    {
      Header: t('date'),
      accessor: 'date',
      Cell: (row) => (
                <span>{moment(row.value).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      Header: ' ',
      Cell: ({ row: { original } }) => <TableButtonGroup
                onView={() => {}}
                onDelete={() => {}}
            />,
    },
  ]

  return <>
        {showModal && (
            <Modal onClose={() => setShowModal(false)}>
                <div>
                    <p className="text-defaultText text-sm text-center">
                        There are no filters yet...
                    </p>
                </div>
            </Modal>
        )}
        <ReportsTable showAddButton title="Imaging" data={imagingData} columns={columns} setShowModal={setShowModal} className="pt-10" />
    </>
}

export default ImagingList
