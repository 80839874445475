import React from 'react'
import EuImage from '../UI/EuImage'

const Footer = () => (
        <div className="px-16">
          <EuImage/>
        </div>
)

export default Footer
