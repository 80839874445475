import React, { useState } from 'react'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import ReportsTable from '../ReportsTable'
import Modal from '../Modals/Modal'
import TableButtonGroup from '../Table/TableButtonGroup'

const colonoscopyData = [
  {
    file_name: 'colonoscopy_report_2024_01.pdf',
    uploader: 'Dr. John Smith',
    date: '2024-01-15',
  },
  {
    file_name: 'colonoscopy_images_2023_11.png',
    uploader: 'Dr. Emily Davis',
    date: '2023-11-20',
  },
  {
    file_name: 'colonoscopy_video_2023_09.mp4',
    uploader: 'Dr. Michael Brown',
    date: '2023-09-12',
  },
]

const ColonoscopyList = () => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  const columns = [
    {
      Header: t('typeFileName'),
      accessor: 'file_name',
    },
    {
      Header: t('uploader'),
      accessor: 'uploader',
    },
    {
      Header: t('date'),
      accessor: 'date',
      Cell: (row) => (
                <span>{moment(row.value).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      Header: ' ',
      Cell: ({ row: { original } }) => <TableButtonGroup
                onView={() => {}}
                onDelete={() => {}}
            />,
    },
  ]

  return <>
      {showModal && (
          <Modal onClose={() => setShowModal(false)}>
              <div>
                  <p className="text-defaultText text-sm text-center">
                      There are no filters yet...
                  </p>
              </div>
          </Modal>
      )}
      <ReportsTable showAddButton title="Colonoscopy" data={colonoscopyData} columns={columns} setShowModal={setShowModal} />
  </>
}

export default ColonoscopyList
